/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AddFilled,
  SubtractFilled,
  ChevronLeft16Filled,
  ChevronRight16Filled,
  // ArrowMinimizeRegular,
} from "@fluentui/react-icons";
import Select from "react-select";
import { components } from "react-select";

import "./documentViewerFooter.style.scss";
/*global */
const options = [
  { value: 0.5, label: "50%" },
  { value: 0.75, label: "75%" },
  { value: 0.9, label: "90%" },
  { value: 1, label: "100%" },
  { value: 1.25, label: "125%" },
  { value: 1.5, label: "150%" },
  { value: 2, label: "200%" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#191571",
    borderColor: "#191571",
    color: "white",
    boxShadow: "none",
    borderRadius: "0px",
    height: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderColor: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
    height: "30px",
    minHeight: "30px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "26px",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#191571" : "#1c1c9b",
    color: "white",
    cursor: "pointer",
    borderTop: "1px solid #fff",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#191571",
    },
    "&:first-child": {
      border: "none",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    marginBottom: "0px",
  }),

  indicatorSeparator: () => ({
    display: "none", // Hide the indicator separator
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0px",
    height: "16px",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span style={{ fontSize: "10px", color: "#E8E8E8", lineHeight: "38px", height: "25px" }}>▼</span>
    </components.DropdownIndicator>
  );
};

const CustomInput = (props) => (
  <components.Input {...props} readOnly /> // Make the input read-only
);

const DocumentViewerFooter = ({
  pageCount,
  onPageChanged,
  scaleEnabled = false,
  scale = 1,
  onScaleChanged,
  pageNumber,
}) => {
  const pageInput = useRef(null);
  const currentPageRef = useRef(1);
  const [page, setPage] = useState(pageNumber);
  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);
  const handlePageChange = (newPage) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  const handleScaleChange = (value) => {
    let newScale = scale + value;
    if (newScale < -10) {
      newScale = 1;
    }
    if (newScale > 12) {
      newScale = 12;
    }
    onScaleChanged && onScaleChanged(newScale);
  };

  const pageManualChange = (e) => {
    const _value = e?.target?.value?.trim();
    const page = Number(_value);
    if (page > pageCount || page < 0 || page % 1 !== 0 || _value === "0") return;
    handlePageChange(_value);
    if (page) {
      currentPageRef.current = page;
    }
  };

  const handleBlur = () => {
    const value = Number(page);
    if (value === 0) {
      handlePageChange(+currentPageRef.current);
    }
  };

  const selectedValue = useMemo(() => {
    const valuesArray = options.map((item) => item.value);

    if (valuesArray.includes(scale)) {
      return options.find((el) => el.value === scale);
    } else {
      return options.find((el) => el.value === 1);
    }
  }, [scale]);

  return (
    <div className="documents_viewer_footer">
      <ChevronLeft16Filled
        className={`icon_style_footer ${page <= 1 ? "disabled_icon" : ""}`}
        onClick={() => {
          if (page > 1) {
            const newPage = +page - 1;
            handlePageChange(newPage);
          }
        }}
      />
      <input
        ref={pageInput}
        className="page_input p4"
        value={page}
        type="text"
        min={1}
        max={pageCount}
        onFocus={() => {
          pageInput.current?.select();
        }}
        onBlur={handleBlur}
        onChange={pageManualChange}
      />
      <div className="page_count">
        <p className="p4">/ {pageCount}</p>
      </div>
      <ChevronRight16Filled
        onClick={() => {
          if (page < pageCount) {
            const newPage = +page + 1;
            handlePageChange(newPage);
          }
        }}
        className={`icon_style_footer ${page === pageCount ? "disabled_icon" : ""}`}
      />
      {scaleEnabled && (
        <div
          style={{
            display: "flex",
            gap: 3,
            marginRight: 4,
            marginLeft: 4,
          }}
        >
          <SubtractFilled
            disabled={scale <= 0.3}
            onClick={() => handleScaleChange(-(5 / 100))}
            className="icon_style_footer"
          />

          <Select
            options={options}
            menuPlacement="top"
            className="footer_dropdown"
            styles={customStyles}
            defaultValue={selectedValue}
            onChange={({ value }) => onScaleChanged && onScaleChanged(value)}
            components={{ DropdownIndicator, Input: CustomInput }}
          />

          <AddFilled disabled={scale === 12} onClick={() => handleScaleChange(5 / 100)} className="icon_style_footer" />
        </div>
      )}
    </div>
  );
};
export default DocumentViewerFooter;
