import { Document24Filled, Mail24Regular, MathFormula24Filled, Person20Filled } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sideBar.scss";
import { logout } from "../../api/userEndpoints";
import { Menu, MenuTrigger, MenuList, MenuItem, MenuPopover } from "@fluentui/react-components";
/* global window*/
const SideBar = () => {
  const location = useLocation();
  const [userData, setUserData] = useState(window.userData?.user || {});
  useEffect(() => {
    const removeListener = window.userData?.addListener((prop, oldValue, newValue) => {
      setUserData(newValue);
    });

    return () => {
      removeListener();
    };
  }, []);
  const listItems = [
    {
      label: "Documents",
      id: "document",
      component: (
        <Link to="/documents" title="Documents">
          <Document24Filled />
        </Link>
      ),
    },
    {
      label: "Formulas",
      id: "formulas",
      component: (
        <Link to="/formulas" title="Formulas">
          <MathFormula24Filled />
        </Link>
      ),
    },
  ];
  return (
    <div className="app_sidebar">
      <div className="top_side">
        <Link to={"/documents"} className="sidebar_logo">
          <img src="/assets/images/svg/cognaize-logo-blue-white.svg" alt="" title="" />
        </Link>
        <ul className="sidebar_menu_list">
          {listItems.map((item) => {
            return (
              <li
                key={item.id}
                className={`sidebar_menu_list_item ${location.pathname.includes(item.id) ? "active" : ""}`}
              >
                {item.component}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="bottom_side">
        <ul className="sidebar_menu_bottom_list">
          <li>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Person20Filled className="profile_menu_trigger" />
              </MenuTrigger>

              <MenuPopover>
                <MenuList className="profile_menu_list">
                  {userData.firstName && userData.lastName && (
                    <MenuItem>
                      <span className="name_icon">
                        {userData.firstName[0]}
                        {userData.lastName[0]}
                      </span>
                      {userData.firstName} {userData.lastName}
                    </MenuItem>
                  )}
                  {userData.email && (
                    <MenuItem>
                      <Mail24Regular />
                      {userData.email}
                    </MenuItem>
                  )}
                  <MenuItem className="button_logout">
                    <button className="btn contained logout_button" onClick={logout}>
                      Logout
                    </button>
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
