import React from "react";
import PropTypes from "prop-types";

import "./loadingCircle.style.scss";
const LoadingCircle = (props) => {
  const { size = "small" } = props;
  return <span className={`loading-circle ${size}`} />;
};
export default LoadingCircle;
LoadingCircle.propTypes = {
  size: PropTypes.string,
};
