import React, { useEffect, useState } from "react";

import Documents from "../pages/Documents";
import "./app.css";
import useBroadCastUserChannel from "../../hooks/useBroadCastUserChannel";
import { disableActions } from "../../commands/actionsCommands";
import Unauthorized from "../pages/Unauthorized";
import { cleanWorksheetId } from "../utils/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import appRouter from "../../appRouter";
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";
import DocumentViewer from "../pages/DocumentViewer";
import RoutesContainer from "../../routesWrappers/RoutesContainer";
import AuthWrapper from "../../routesWrappers/AuthWrapper";
import UnauthorizedRoutes from "../../routesWrappers/UnauthorizedRoutes";
import Formulas from "../pages/Formulas";
import Login from "../pages/Login";
import { getCurrentUser } from "../../api/userEndpoints";
import { getOrCreateWorkbookId } from "../../utils";
dayjs.extend(utc);
/*
  global console BroadcastChannel Excel window
*/

const App = () => {
  const [messageFromModal, setMessageFromModal] = useState("");

  const { loggedIn } = useBroadCastUserChannel();
  useEffect(() => {
    if (!loggedIn) {
      disableActions();
    }
  }, [loggedIn]);
  useEffect(() => {
    const fetchUser = async () => {
      const response = await getCurrentUser();
      if (response.status === "success") {
        window.userData.user = response.data;
      }
    };
    if (loggedIn) {
      fetchUser();
    }
  }, [loggedIn]);
  const channel = new BroadcastChannel("office-addin-channel");
  useEffect(() => {
    let sheetDeletedEvent;

    Excel.run(async (context) => {
      const workbook = context.workbook;

      // Event listener for worksheet deletion
      sheetDeletedEvent = workbook.worksheets.onDeleted.add((eventArgs) => {
        console.log(`Sheet deleted: ${eventArgs.worksheetId}`);
        console.log(`Sheet eventArgs:    ${JSON.stringify(eventArgs)}`);
        const workBookId = getOrCreateWorkbookId();
        window.storeData &&
          window.storeData[workBookId] &&
          delete window.storeData[workBookId][cleanWorksheetId(eventArgs.worksheetId)];
        // Handle your logic here, e.g., updating UI or state
      });

      await context.sync(); // Sync the event listener
    }).catch((error) => {
      console.error(error);
    });

    // Cleanup on component unmount
    return () => {
      if (sheetDeletedEvent) {
        Excel.run(async (context) => {
          sheetDeletedEvent.remove(); // Remove the event listener
          await context.sync();
        }).catch((error) => {
          console.error(error);
        });
      }
    };
  }, []); // Empty dependency array to run this only once on component mount
  useEffect(() => {
    console.log(messageFromModal, "messageFromModal;");
  }, [messageFromModal]);

  useEffect(() => {
    channel.onmessage = (event) => {
      const message = event.data;
      if (message.type === "FROM_MODAL") {
        console.log("Message from modal:", message.payload);
        setMessageFromModal(message.payload);
      }
    };

    return () => {
      channel.close();
    };
  }, [channel]);

  return (
    <div className="app_wrapper">
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to={"/documents"} />
          </Route>
          <Route
            exact
            path="/documents"
            component={() => (
              <AuthWrapper>
                <RoutesContainer>
                  <Documents />
                </RoutesContainer>
              </AuthWrapper>
            )}
          />
          <Route
            exact
            path="/documents/:scopeId"
            component={() => {
              return (
                <AuthWrapper>
                  <RoutesContainer>
                    <DocumentViewer />
                  </RoutesContainer>
                </AuthWrapper>
              );
            }}
          />
          <Route
            exact
            path={"/formulas"}
            component={() => {
              return (
                <AuthWrapper>
                  <RoutesContainer>
                    <Formulas />
                  </RoutesContainer>
                </AuthWrapper>
              );
            }}
          />

          <Route
            exact
            path="/login"
            component={() => {
              return (
                <UnauthorizedRoutes>
                  <Login />
                </UnauthorizedRoutes>
              );
            }}
          />
          <Route
            exact
            path="/unauthorized"
            component={() => {
              return (
                <UnauthorizedRoutes>
                  <Unauthorized />
                </UnauthorizedRoutes>
              );
            }}
          />
          <Route exact path="/*" component={() => <div>grdon</div>} />
        </Switch>
      </HashRouter>
    </div>
  );
};

export default App;
