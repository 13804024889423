/* global Excel console window*/
import { getLetterByIndex } from "../utils";
import { cleanWorksheetId } from "./utils/utils";

export async function insertText(text) {
  // Write text to the top left cell.
  try {
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange("A1");
      range.values = [[text]];
      range.format.autofitColumns();
      await context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
}

export async function insertOntologies({ ontology, header, sheetType, scopeId }) {
  try {
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      let sheet;

      if (sheetType === "existing") {
        sheet = context.workbook.worksheets.getActiveWorksheet();
      } else if (sheetType === "new") {
        const sheets = context.workbook.worksheets;
        sheets.load("items");
        await context.sync(); // Synchronize the context to fetch the sheet data
        const sheetNames = sheets.items.map((sheet) => sheet.name);
        let newSheetIndex = 1;
        let newSheetName = `Sheet${newSheetIndex}`;

        // Find the next available sheet name that is not already taken
        while (sheetNames.includes(newSheetName)) {
          newSheetIndex++;
          newSheetName = `Sheet${newSheetIndex}`;
        }

        sheet = sheets.add(newSheetName);
        sheet.activate();
      }

      if (!sheet) {
        console.error("Error: No active worksheet found.");
        return;
      }

      // Clear data validation and number format for all cells in the worksheet
      const range = sheet.getRange(); // Get the entire sheet's range

      // Clear any data validation rules
      range.dataValidation.clear();

      // Reset the number format to "General" (default format)
      range.numberFormat = [["General"]]; // You can also use [['@']] for text format

      await context.sync();

      // Clear existing data and ungroup
      const usedRange = sheet.getUsedRange();
      if (usedRange) {
        usedRange.clear();
        usedRange.ungroup(); // Ungroup all ranges
      }
      // const rangeToUnLock = sheet.getRange("A1:B1"); // Specify the range you want to lock
      // rangeToUnLock.format.protection.locked = false;
      if (header.length > 0) {
        const headerRow = header; // Get the first row of headers
        const columnCount = headerRow.length; // Get number of columns in the header row
        const endColumn = String.fromCharCode(64 + columnCount); // Convert number to letter (e.g., 1 -> A, 2 -> B, etc.)
        const headerRange = sheet.getRange(`A1:${endColumn}1`); // Define the range for headers
        headerRange.values = [headerRow]; // Set the header values in the first row
        headerRange.format.font.bold = true; // Make the header bold

        headerRange.format.fill.color = "#074CE5";
        headerRange.format.font.color = "#FFFFFF";
      }
      sheet.load("id");
      await context.sync();
      const sheetId = cleanWorksheetId(sheet.id);
      // Helper function to insert a group and return the range
      const cellsData = { id: sheetId, data: {} };
      const insertGroup = (_data, startRow) => {
        const data = [];
        _data.map((item, index) => {
          item.groupValue.forEach((groupValue, valueIndex) => {
            cellsData.data[`${getLetterByIndex(valueIndex + 1)}${startRow + index}`] = item;
            /*   if (valueIndex > 0) {
              Excel.run(async (context) => {
                const sheet = context.workbook.worksheets.getActiveWorksheet();

                const range = sheet.getRange(`${getLetterByIndex(valueIndex + 1)}${startRow + index}`);
                range.dataValidation.clear();
                await context.sync();

                if (item.data_type === "Date") {
                  range.numberFormat = [["mm/dd/yyyy"]];
                  range.dataValidation.rule = {
                    custom: {
                      formula: `=OR(ISFORMULA(${getLetterByIndex(valueIndex + 1)}${startRow + index}), NOT(ISTEXT(${getLetterByIndex(valueIndex + 1)}${startRow + index})))`,
                    },
                  };
                  range.dataValidation.errorAlert = {
                    message: "Sorry, only Date allowed",
                    showAlert: true, // The default is 'true'.
                    style: Excel.DataValidationAlertStyle.stop,
                    title: "Only date Allowed",
                  };
                }
                if (item.data_type === "Numeric") {
                  range.numberFormat = [["0.00"]];
                  range.dataValidation.rule = {
                    custom: {
                      formula: `=OR(ISFORMULA(${getLetterByIndex(valueIndex + 1)}${startRow + index}),ISNUMBER(${getLetterByIndex(valueIndex + 1)}${startRow + index}))`,
                    },
                  };

                  // Optionally set custom error messages for invalid input
                  range.dataValidation.errorAlert = {
                    message: "Please enter a valid number.", // Custom error message
                    showAlert: true, // Show the alert if input is invalid
                    style: Excel.DataValidationAlertStyle.stop, // Block input if invalid
                    title: "Invalid Input",
                  };
                }
                if (item.data_type == "Text") {
                  range.dataValidation.rule = {
                    custom: {
                      formula: `=OR(ISFORMULA(${getLetterByIndex(valueIndex + 1)}${startRow + index}),ISTEXT(${getLetterByIndex(valueIndex + 1)}${startRow + index}))`,
                    },
                  };

                  // Optionally set a custom error message for invalid input
                  range.dataValidation.errorAlert = {
                    message: "Please enter valid text.", // Custom error message
                    showAlert: true, // Show alert if input is invalid
                    style: Excel.DataValidationAlertStyle.stop, // Block invalid input
                    title: "Invalid Input",
                  };
                }

                await context.sync();
              });
            } else {
              Excel.run(async (context) => {
                const sheet = context.workbook.worksheets.getActiveWorksheet();

                const range = sheet.getRange(`${getLetterByIndex(valueIndex + 1)}${startRow + index}`);
                range.dataValidation.clear();
                await context.sync();

                range.dataValidation.rule = {
                  custom: {
                    formula: `=EXACT(${getLetterByIndex(valueIndex + 1)}${startRow + index} , "${groupValue}")`,
                  },
                };

                // Optionally set a custom error message for invalid input
                range.dataValidation.errorAlert = {
                  message: "This cell can't be modified", // Custom error message
                  showAlert: true, // Show alert if input is invalid
                  style: Excel.DataValidationAlertStyle.stop, // Block invalid input
                  title: "Readonly",
                };

                await context.sync();
              });
            } */
          });
          data.push(item.groupValue);
        });
        const endRow = startRow + data.length - 1;
        const range = sheet.getRange(`A${startRow}:B${endRow}`);
        range.values = data;
        range.format.autofitColumns();

        // Set the first value of the group to bold
        const headerRange = sheet.getRange(`A${endRow}`);
        headerRange.format.font.bold = true;

        headerRange.format.autofitColumns();
        return { startRow, endRow };
      };

      // Loop through ontology array to insert groups
      let currentRow = 2; // Starting row
      ontology.forEach((groupData) => {
        const group = insertGroup(groupData, currentRow);
        currentRow = group.endRow + 1; // Add 1 row as spacer after each group

        // Group rows in Excel (skip header row for each group)
        sheet.getRange(`A${group.startRow}:A${group.endRow - 1}`).group();
      });

      window.storeData[sheetId] = { [scopeId]: cellsData.data };

      await context.sync();

      // const allCells = sheet.getRange("A1:XFD1048576"); // Unlock all possible cells
      // allCells.format.protection.locked = false;

      // // Lock specific cells
      // const rangeToLock = sheet.getRange("A1:B1"); // Specify the range you want to lock
      // rangeToLock.format.protection.locked = true;

      // Protect the sheet to apply the lock
      // const password = "12345678"; // Replace with the desired password
      // sheet.protection.protect({}, password);

      // await context.sync();

      console.log("Data written successfully with groups.");
    });
  } catch (error) {
    console.error("Error: ", error);
  }
}
