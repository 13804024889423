import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getFiles } from "../../../api/endoints";
import "react-responsive-pagination/themes/minimal.css";

import "./documents.style.scss";

import DocumentsList from "./DocumenstList/List";
import usePrevious from "../../hooks/usePreviouse";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import DocumentsSearch from "../../components/DocumentsSearch";

/*global*/

const Documents = () => {
  const history = useHistory();

  const [filesData, setFilesData] = useState([]);

  const [filesLoading, setFilesLoading] = useState(false);

  const [documentFilter, setDocumentFilter] = useState({ pageSize: 10, page: 1, search: "" });

  const [dataInfo, setDataInfo] = useState({ totalElements: 0, totalPages: 0 });
  const prevLoading = usePrevious(filesLoading);

  useEffect(() => {
    setFilesLoading(true);
    getFiles({
      pageNumber: documentFilter?.page - 1,
      pageSize: documentFilter?.pageSize,
      search: documentFilter?.search,
      status: "Completed",
    })
      .then((response) => {
        const { content, totalElements, totalPages } = response.data;
        setFilesData(content);
        setDataInfo({ totalElements, totalPages });
        setFilesLoading(false);
      })
      .catch(() => {
        setFilesData([]);
        setDataInfo({ totalElements: 0, totalPages: 0 });
        setFilesLoading(false);
      });
  }, [documentFilter?.page, documentFilter?.pageSize, documentFilter?.search]);
  return (
    <div className="documents-page">
      <>
        <DocumentsSearch
          disabled={!documentFilter.search && !filesLoading && !filesData.length}
          callback={(search) => {
            setDocumentFilter((prev) => {
              return { ...prev, search, page: 1 };
            });
          }}
        />

        {filesData.length > 0 && (
          <>
            <DocumentsList
              loading={false}
              files={filesData}
              selectFile={(id) => {
                history.push({
                  pathname: `/documents/${id}`,
                  search: `?page=1`,
                });
              }}
            />
            <CustomPagination
              pageNumber={+documentFilter.page}
              pageCount={+dataInfo.totalPages}
              onPageChange={(page) => {
                setDocumentFilter((prev) => {
                  return { ...prev, page };
                });
              }}
              pageSize={documentFilter?.pageSize}
              onPageSizeChange={(pageSize) => {
                setDocumentFilter((prev) => {
                  return { ...prev, page: 1, pageSize };
                });
              }}
            />
          </>
        )}
      </>
      {((prevLoading && !filesLoading) || (prevLoading === false && !filesLoading)) && !filesData.length && (
        <div className="pdf-view-center">No Files</div>
      )}
    </div>
  );
};

export default React.memo(Documents);
