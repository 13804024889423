import { useEffect, useState } from "react";
/* global  BroadcastChannel  localStorage console*/
const useBroadCastUserChannel = (props) => {
  const { onLogout, onLogin } = props || {};
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("accessToken"));
  useEffect(() => {
    const userChannel = new BroadcastChannel("user-channel");

    userChannel.onmessage = (event) => {
      try {
        const message = event.data;
        if (message.type === "LOGOUT") {
          setLoggedIn(false);
          onLogout && onLogout();
        }
        if (message.type === "LOGIN") {
          setLoggedIn(true);
          onLogin && onLogin();
        }
      } catch (error) {
        console.log(error, "errorerrorerror");
      }
    };
  }, []);
  return {
    loggedIn,
  };
};
export default useBroadCastUserChannel;
