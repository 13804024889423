/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles, Tooltip } from "@fluentui/react-components";
const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "rgba(76, 76, 76, 0.9)",
    color: "#fff",
  },
});

const AppTooltip = ({ children, className, tipMessage, ...rest }) => {
  const styles = useStyles();
  return (
    <Tooltip
      {...rest}
      className={`app_tooltip ${className}`}
      content={{ children: tipMessage, className: styles.tooltip }}
      relationship="label"
    >
      {children}
    </Tooltip>
  );
};

export default AppTooltip;
