import React from "react";
import { useEffect, useState } from "react";
import { cleanWorksheetId } from "../taskpane/utils/utils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Layout from "../reusableComponents/Layout";
import LayerLoading from "../reusableComponents/LayerLoading";
import { getLineageData, getOrCreateWorkbookId } from "../utils";
const documentChannel = new BroadcastChannel("document-channel");
const regexAskMelody = /\.ASKMELODY\(([^,]+),/;
const regexSetMnemonic = /\.SET\((.+)\)/;
const regexGetMnemonic = /\.GET\((.+)\)/;
const regexOcr = /\.OCR\((.+)\)/;
const regexForCellCoordinate = /^\$?[A-Z]+\$?\d+$/;

/* global Excel console  window BroadcastChannel URLSearchParams setTimeout clearTimeout */
function splitExcelParams(input) {
  // Regular expression to split by commas that are not inside curly braces
  const regex = /,(?![^{]*\})/;

  // Split the input string using the regex
  const params = input.split(regex);

  // Trim any surrounding whitespace and return the array of parameters
  return params.map((param) => param.trim());
}
const generateQueryParams = (params) => {
  const searchParams = new URLSearchParams(params); // Create URLSearchParams object from the params object
  return searchParams.toString(); // Convert to query string format
};
async function handleExcelInputForCoords(input) {
  // Regular expression to detect cell ranges, Excel arrays, and dynamic arrays (with #)
  // eslint-disable-next-line no-useless-escape
  const regex = /([A-Z]+[0-9]+:[A-Z]+[0-9]+)|(\{[^\}]+\})|([A-Z]+[0-9]+#)/g;

  // Match the patterns
  const matches = input.match(regex);

  // If there are matches, process each one
  if (matches) {
    // Use Excel.run for asynchronous operations
    return await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      // Prepare an array to store the results
      const results = [];

      for (let match of matches) {
        if (match.includes(":")) {
          // Case 1: Handle Excel Range (e.g., R21:V21)
          const [startCell, endCell] = match.split(":");
          const range = sheet.getRange(`${startCell}:${endCell}`);
          range.load("values");
          await context.sync();

          results.push({
            type: "range",
            startCell,
            endCell,
            values: range.values[0], // Add the loaded values
          });
        } else if (match.startsWith("{")) {
          // Case 2: Handle Excel Array (e.g., {1, 6.07553366174055, ...})
          const arrayValues = match
            .slice(1, -1) // Remove curly braces
            .split(",") // Split by comma
            .map(Number); // Convert to numbers
          results.push({
            type: "array",
            values: arrayValues,
          });
        } else if (match.endsWith("#")) {
          // Case 3: Handle Dynamic Array Reference (e.g., R21#)
          const cell = match.slice(0, -1); // Remove the trailing '#'
          const range = sheet.getRange(cell + "#");
          range.load("values"); // Load the dynamic array values
          await context.sync();

          results.push({
            type: "dynamicArray",
            cell,
            values: range.values[0], // Add the loaded values
          });
        }
      }

      return results; // Return all processed results
    });
  }

  // Return an empty array if no matches found
  return [];
}
// eslint-disable-next-line react/prop-types
const RoutesContainer = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { scopeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeSheet, setActiveSheet] = useState("");
  useEffect(() => {
    const getActiveSheetName = async () => {
      try {
        await Excel.run(async (context) => {
          const activeSheet = context.workbook.worksheets.getActiveWorksheet();

          activeSheet.load("id");
          await context.sync(); // Sync the workbook state
          // console
          setActiveSheet(cleanWorksheetId(activeSheet.id));
        });
      } catch (error) {
        console.error("Error getting active sheet name: ", error);
      }
    };
    getActiveSheetName();
  }, []);
  useEffect(() => {
    let selectionEvent;

    Excel.run(async (context) => {
      const workbook = context.workbook;
      // Event listener for selection change
      selectionEvent = workbook.onSelectionChanged.add(async (eventArgs) => {
        const activeSheet = eventArgs.workbook.worksheets.getActiveWorksheet();
        activeSheet.load("id");
        await context.sync();

        setActiveSheet(cleanWorksheetId(activeSheet.id));
      });

      await context.sync(); // Sync the event listener
    }).catch((error) => {
      console.error(error);
    });

    // Cleanup on component unmount
    return () => {
      if (selectionEvent) {
        Excel.run({ delayForCellEdit: true }, async (context) => {
          selectionEvent.remove(); // Remove the event listener
          await context.sync();
        }).catch((error) => {
          console.error(error);
        });
      }
    };
  }, []);

  useEffect(() => {
    let eventHandler;
    Excel.run({ delayForCellEdit: true }, async (context) => {
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      eventHandler = worksheet.onSingleClicked.add(handleSingleClick);
      await context.sync();
    });

    async function handleSingleClick(event) {
      if (window["lineageEnabled"] !== "on") {
        return;
      }
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const range = sheet.getRange(event.address);
        range.load("formulas");
        await context.sync();
        const formula = range.formulas[0][0]; // Assuming single cell range

        const matchAskMelody = formula.match(regexAskMelody);
        const matchOcr = formula.match(regexOcr);
        const matchSetMnemonic = formula.match(regexSetMnemonic);
        const matchGetMnemonic = formula.match(regexGetMnemonic);
        if (matchOcr || matchAskMelody || matchGetMnemonic || matchSetMnemonic) {
          setLoading(true);
        }
      });

      window.cellClickTimeout && clearTimeout(window.cellClickTimeout);
      return (window.cellClickTimeout = setTimeout(() => {
        Excel.run({ delayForCellEdit: true }, async (context) => {
          const sheet = context.workbook.worksheets.getActiveWorksheet();
          const range = sheet.getRange(event.address);
          range.load("formulas");
          await context.sync();

          const formula = range.formulas[0][0]; // Assuming single cell range

          const matchAskMelody = formula.match(regexAskMelody);
          const matchOcr = formula.match(regexOcr);
          const matchSetMnemonic = formula.match(regexSetMnemonic);
          const matchGetMnemonic = formula.match(regexGetMnemonic);
          let routePath = "/documents";
          const queryParams = {};
          let routeState = null;

          if (matchAskMelody && matchAskMelody[1]) {
            let firstParam = matchAskMelody[1].trim();
            let _firstParamValue = "";

            if (regexForCellCoordinate.test(firstParam)) {
              const paramRange = sheet.getRange(firstParam);
              paramRange.load("values");
              await context.sync();

              const firstParamValue = paramRange.values[0][0];
              routePath += `/${firstParamValue}`;
              _firstParamValue = firstParamValue;
            } else {
              firstParam = firstParam.replace(/^"(.*)"$/, "$1");
              routePath += `/${firstParam}`;
              _firstParamValue = firstParam;
            }

            const lineageFromStore = getLineageData({
              sheetId: activeSheet,
              scopeId: _firstParamValue,
              workbookId: getOrCreateWorkbookId(),
              cellReference: event.address,
            });
            if (lineageFromStore?.lineage?.page_number) {
              const newPage = lineageFromStore.lineage.page_number;
              queryParams.page = newPage;
              routeState = { activeCellCoords: event.address };
            }

            const routeData = { pathname: routePath, search: "?" + generateQueryParams(queryParams) };
            if (routeState) {
              routeData.state = routeState;
            }
            history.replace(routeData);
          } else if (matchOcr && matchOcr[1]) {
            console.log("matchOcr");
            const params = splitExcelParams(matchOcr[1]) || {}; // Split parameters by commas

            let _firstParamValue = "";

            for (const [index, param] of Object.entries(params)) {
              if (+index === 0) {
                if (regexForCellCoordinate.test(param)) {
                  // If it's a cell reference, get the value from that cell
                  const paramRange = sheet.getRange(param);
                  paramRange.load("values"); // Load the cell value
                  await context.sync();

                  const firstParamValue = paramRange.values[0][0];
                  routePath += `/${firstParamValue}`;
                  _firstParamValue = firstParamValue;
                  //   history.push(`/documents/${firstParamValue}`);
                } else {
                  const _param = param.replace(/^"(.*)"$/, "$1");
                  routePath += `/${_param}`;
                  _firstParamValue = _param;
                }
              }
              if (+index === 1) {
                const data = await handleExcelInputForCoords(param);
                if (data.length) {
                  const page = data[0].values[0];
                  queryParams.page = page;
                }
              }
              if (+index === params.length - 1) {
                const lineageFromStore = getLineageData({
                  sheetId: activeSheet,
                  scopeId: _firstParamValue,
                  workbookId: getOrCreateWorkbookId(),
                  cellReference: event.address,
                });
                if (lineageFromStore) {
                  routeState = { activeCellCoords: event.address };
                }
                const routeData = { pathname: routePath, search: "?" + generateQueryParams(queryParams) };
                if (routeState) {
                  routeData.state = routeState;
                }
                history.replace(routeData);
              }
            }
          } else if (matchSetMnemonic && matchSetMnemonic[1]) {
            const params = splitExcelParams(matchSetMnemonic[1]) || {}; // Split parameters by commas
            let _firstParamValue = "";
            for (const [index, param] of Object.entries(params)) {
              if (+index === 0) {
                if (regexForCellCoordinate.test(param)) {
                  // If it's a cell reference, get the value from that cell
                  const paramRange = sheet.getRange(param);
                  paramRange.load("values"); // Load the cell value
                  await context.sync();

                  const firstParamValue = paramRange.values[0][0];
                  routePath += `/${firstParamValue}`;
                  _firstParamValue = firstParamValue;
                  //   history.push(`/documents/${firstParamValue}`);
                } else {
                  const _param = param.replace(/^"(.*)"$/, "$1");
                  routePath += `/${_param}`;
                  _firstParamValue = _param;
                }
              }

              if (+index === params.length - 1) {
                const lineageFromStore = getLineageData({
                  sheetId: activeSheet,
                  scopeId: _firstParamValue,
                  workbookId: getOrCreateWorkbookId(),
                  cellReference: event.address,
                });
                if (lineageFromStore) {
                  routeState = { activeCellCoords: event.address };
                }
                const data = await handleExcelInputForCoords(param);

                if (data.length) {
                  const page = data[0].values[0];
                  queryParams.page = page;
                }
                const routeData = { pathname: routePath, search: "?" + generateQueryParams(queryParams) };
                if (routeState) {
                  routeData.state = routeState;
                }
                history.replace(routeData);
              }
            }
          } else if (matchGetMnemonic && matchGetMnemonic[1]) {
            const params = splitExcelParams(matchGetMnemonic[1]) || {}; // Split parameters by commas
            let firstParam = params[0];
            if (regexForCellCoordinate.test(firstParam)) {
              const paramRange = sheet.getRange(firstParam);
              paramRange.load("values");
              await context.sync();

              const firstParamValue = paramRange.values[0][0];
              routePath += `/${firstParamValue}`;
            } else {
              firstParam = firstParam.replace(/^"(.*)"$/, "$1");
              routePath += `/${firstParam}`;
            }

            const routeData = { pathname: routePath };

            history.replace(routeData);
          } else {
            const lineageFromStore = getLineageData({
              sheetId: activeSheet,
              scopeId,
              workbookId: getOrCreateWorkbookId(),
              cellReference: event.address,
            });
            if (lineageFromStore) {
              const newPage = lineageFromStore.lineage.page_number;
              documentChannel.postMessage({
                type: "SET_ACTIVE_CELL_COORDS",
                data: {
                  cellCoordinate: event.address,
                  page: newPage,
                },
              });
            } else {
              const routeData = { pathname: location.pathname, search: location.search };
              history.replace(routeData);
            }
          }
          setLoading(false);
        });
      }, 1000));
    }
    return () => {
      window.cellClickTimeout && clearTimeout(window.cellClickTimeout);

      Excel.run(async (context) => {
        if (eventHandler) {
          // Remove the event listener
          eventHandler.remove(handleSingleClick);
          await context.sync();
          console.log("Single click event listener removed.");
        } else {
          console.log("No event listener to remove.");
        }
      });
    };
  }, [activeSheet, scopeId, location.search]);
  return (
    <Layout>
      {loading && <LayerLoading />} {children}
    </Layout>
  );
};
export default RoutesContainer;
