/* global console */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "../../../api/userEndpoints";
import { EyeRegular, EyeOffRegular } from "@fluentui/react-icons";
import LoadingCircle from "../../../reusableComponents/LoadingCircle/LoadingCircle";
import "./login.scss";
const Login = () => {
  const [responseErrors, setResponseErrors] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const handleLogin = async (data) => {
    setLoading(true);
    const response = await login(data);
    if (response.status === "success") {
      console.log(response, "success");
    } else {
      if (response.data?.data?.errors[0] === "invalid_grant") {
        setResponseErrors({
          email: {
            error: true,
            message: "",
          },
          password: {
            error: true,
            message: "Wrong email or password!",
          },
        });
      } else {
        setResponseErrors({
          email: {
            error: true,
            message: "",
          },
          password: {
            error: true,
            message: "Couldn't LogIn: something went wrong!",
          },
        });
      }
    }
    setLoading(false);
  };
  const onSubmit = (data) => {
    handleLogin(data);
  };
  const passwordError = errors["password"] || responseErrors?.["password"];
  const emailError = errors["email"] || responseErrors?.["email"];

  return (
    <div className="login_view">
      <div className="login_container">
        <img src="/assets/images/svg/logo-rgb-cognaize-blue-big.svg" />
        <p>Please sign in to your Cognaize account.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input_block">
            <div className="input_field">
              <input
                data-id="login-email"
                placeholder="Email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => {
                  setValue("email", e.target.value);
                  if (responseErrors) {
                    setResponseErrors({});
                  }
                }}
                className={`${emailError ? "error_field" : ""}`}
              />
            </div>

            {emailError?.message && <div className="error_block">{emailError?.message}</div>}
          </div>

          <div className="input_block">
            <div className="input_field">
              <input
                type={`${passwordVisible ? "text" : "password"}`}
                data-id="login-password"
                placeholder="Password"
                size="small"
                className={`${passwordError ? "error_field" : ""}`}
                {...register("password", { required: "Password is required." })}
                onChange={(e) => {
                  setValue("password", e.target.value);
                  if (responseErrors) {
                    setResponseErrors({});
                  }
                }}
              />
              {passwordVisible ? (
                <EyeRegular onClick={() => setPasswordVisible(false)} />
              ) : (
                <EyeOffRegular onClick={() => setPasswordVisible(true)} />
              )}
            </div>

            {passwordError?.message && <div className="error_block">{passwordError?.message}</div>}
          </div>

          <button disabled={loading} className="btn login_btn contained" data-id="login-submit" type="submit">
            {loading && <LoadingCircle />} Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
