import React from "react";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Divider } from "@fluentui/react-components";
import "./formulas.scss";

/* global process */
const Formulas = () => {
  const appName = process.env.APP_NAME.toUpperCase();
  const formulaList = [
    {
      id: "ASKMELODY",
      name: `=${appName}.ASKMELODY`,
      fullName: `=${appName}.ASKMELODY(scopeId, query)`,
      description: "Extracts specific data from financial documents using the scope ID and query.",
      examples: [`=${appName}.ASKMELODY(7aeec7c8-86c7-4763-a081-a835d205ec14, "How much is total revenue?")`],
      params: [
        {
          name: "scopeId",
          value: "The unique ID of the document.",
        },
        {
          name: "query",
          value: "Query to be processed by Melody AI",
        },
      ],
    },
    {
      id: "OCR",
      name: `=${appName}.OCR`,
      fullName: `=${appName}.OCR(scopeId, coordinates)`,
      description: "Extracts data from a specific area in a document using the scope ID, page number, and coordinates.",
      examples: [
        `=${appName}.OCR(7aeec7c8-86c7-4763-a081-a835d205ec14, A1#)`,
        `=${appName}.OCR(7aeec7c8-86c7-4763-a081-a835d205ec14, R1:V1)`,
      ],
      params: [
        {
          name: "scopeId",
          value: "The unique ID of the document",
        },
        {
          name: "coordinates",
          value: "An Excel array or range specifying the page number and coordinates (left, top, right, bottom).",
        },
      ],
    },

    {
      id: "SET",
      name: `=${appName}.SET`,
      fullName: `=${appName}.SET(scopeId, knowledgeGraph, mnemonic, value, coordinates)`,
      description:
        "Assigns a specific value from coordinates within a document to a defined knowledge graph with a specific Mnemonic name.",
      examples: [`=${appName}.SET(7aeec7c8-86c7-4763-a081-a835d205ec14, "FinancialData", "Revenue", 1000000, A1#)`],
      params: [
        {
          name: "scopeId",
          value: "The unique ID of the document",
        },
        {
          name: "knowledgeGraph",
          value: "Knowledge Graph",
        },
        {
          name: "mnemonic",
          value: "Name of Mnemonic",
        },
        {
          name: "value",
          value: "Value of Mnemonic",
        },

        {
          name: "coordinates",
          value: "An Excel array or range specifying the page number and coordinates (left, top, right, bottom)",
        },
      ],
    },
    {
      id: "GET",
      name: `=${appName}.GET`,
      fullName: `=${appName}.GET(scopeId, knowledgeGraph, mnemonic)`,
      description:
        "Retrieves the value associated with a specific mnemonic in the knowledge graph for a given document.",
      examples: [`=${appName}.GET(7aeec7c8-86c7-4763-a081-a835d205ec14, "FinancialData", "Revenue")`],
      params: [
        {
          name: "scopeId",
          value: "The unique ID of the document",
        },
        {
          name: "knowledgeGraph",
          value: "Knowledge Graph",
        },
        {
          name: "mnemonic",
          value: " Name of Mnemonic",
        },
      ],
    },
  ];
  return (
    <div className="formula_page">
      <Accordion collapsible>
        {formulaList.map((item) => {
          return (
            <AccordionItem className="formula_accordion_item" value={item.id} key={item.id}>
              <AccordionHeader as={"div"} className="formula_accordion_header" size="medium" expandIconPosition="end">
                {item.name}
              </AccordionHeader>
              <AccordionPanel>
                <div className="formula_accordion_description">{item.description}</div>
                {/* <div>
                  <code>{item.fullName}</code>
                </div> */}
                <Divider className="formula_divider" />
                <div className="formula_accordion_examples">
                  <div className="block_title">Example</div>
                  {item.examples.map((example, index) => {
                    return (
                      <code key={index} className="example_code">
                        {example}
                      </code>
                    );
                  })}
                </div>
                <Divider className="formula_divider" />
                <div className="syntax_blok">
                  <div className="block_title">Syntax</div>
                  <code className="syntax_code">{item.fullName}</code>
                  <div className="params_block">
                    {item.params.map((param, index) => {
                      return (
                        <div className="params_item" key={index}>
                          <span>{param.name}</span>
                          <span>{param.value}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};
export default Formulas;
