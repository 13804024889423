import axiosInstance from "./apiService";
/*global  console localStorage  BroadcastChannel indexedDB*/
const userChannel = new BroadcastChannel("user-channel");

export const login = async ({ email, password }) => {
  return axiosInstance
    .post("/auth/login", {
      email,
      password,
    })
    .then((response) => {
      // window.closeUserDialog && window.closeUserDialog();
      localStorage.setItem("accessToken", response.data.data.accessToken);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
      userChannel.postMessage({ type: "LOGIN", data: response.data.data });

      return response.data;
    })
    .catch((error) => {
      console.log(error, "error");
      return error.response;
    });
};
//
export const logout = async () => {
  return axiosInstance
    .post(`/auth/logout`, {
      refreshToken: localStorage.getItem("refreshToken"),
    })
    .then(async (response) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("scopeId");

      userChannel.postMessage({ type: "LOGOUT" });

      const request = indexedDB.open("ontologyDatabase", 1);

      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("ontologyObjectStore")) {
          db.createObjectStore("ontologyObjectStore", { keyPath: "id" });
        }
      };
      request.onsuccess = function (event) {
        const db = event.target.result;

        const transaction = db.transaction("ontologyObjectStore", "readwrite");
        const objectStore = transaction.objectStore("ontologyObjectStore");
        objectStore.clear();
      };
      return response.data;
    })
    .catch((error) => {
      console.log(error, "error");
      return error.response;
    });
};
export const getCurrentUser = async () => {
  const { data } = await axiosInstance.get(`/users/current`);
  return data;
};
