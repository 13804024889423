/* global localStorage console Office window*/
export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const getLetterByIndex = (index) => {
  if (index >= 1 && index <= 26) {
    return alphabet[index - 1];
  } else {
    return "Invalid index";
  }
};
export const getLetterIndex = (letter) => {
  return alphabet.indexOf(letter.toUpperCase()) + 1;
};

export const getScopeIdFromStorage = () => {
  return localStorage.getItem("scopeId");
};

export const getOrCreateWorkbookId = (id) => {
  const settings = Office.context.document.settings;
  let workbookId = settings.get("workbookId");

  if (!workbookId) {
    workbookId = "workbook_" + (id || new Date().getTime());
    settings.set("workbookId", workbookId);
    settings.saveAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.error("Failed to save workbook ID:", result.error.message);
      } else {
        console.log("Workbook ID saved successfully.");
      }
    });
  }

  return workbookId;
};

export const storeLineageData = ({ sheetId, scopeId, workbookId, cellReference, lineage }) => {
  //if lineage not passed it will removed from data
  if (!sheetId || !scopeId || !workbookId || !cellReference) return;
  const dataFromWorkbookId = window.storeData[workbookId] || {};
  const dataFromSheetId = dataFromWorkbookId[sheetId] || {};
  const dataFromSheetAndScope = dataFromSheetId[scopeId] || {};

  window.storeData[workbookId] = {
    ...dataFromWorkbookId,
    [sheetId]: {
      ...dataFromSheetId,
      [scopeId]: {
        ...dataFromSheetAndScope,
        [cellReference]: {
          ...(window.storeData[sheetId]
            ? window.storeData[sheetId][cellReference] || { newCell: true, id: Date.now() }
            : {}),
          scopeId,
          lineage,
        },
      },
    },
  };
};

export const getLineageData = ({ sheetId, scopeId, workbookId, cellReference }) => {
  const workBookData = window.storeData[workbookId];

  if (workBookData) {
    const workSheetData = workBookData[sheetId];
    if (workSheetData) {
      const scopeData = workSheetData[scopeId];
      if (scopeData && scopeData[cellReference] && scopeData[cellReference]) {
        return scopeData[cellReference];
      }
    }
  }
  return null;
};
