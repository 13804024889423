/* global Office console  window  Excel indexedDB localStorage*/

import dayjs from "dayjs";
import { saveDocument, finishDocument, reopenDocument } from "../api/actionsService";
import { cleanWorksheetId } from "../taskpane/utils/utils";
import { getLetterByIndex } from "../utils";

const mergeOntologyData = (ontology, propertiesId, sheetData, activeSheetId) => {
  const proxyData = window.storeData[activeSheetId];
  if (!proxyData || !ontology || !propertiesId || !activeSheetId) return;

  const lineageData = {};

  sheetData.forEach((element, elementIndex) => {
    if (elementIndex < 1) return;
    for (let i = 1; i < propertiesId.length; i++) {
      const currentLetter = getLetterByIndex(i + 1);
      const currentProxyElement = proxyData[`${currentLetter}${elementIndex + 1}`];
      if (currentProxyElement) {
        const lineage = currentProxyElement.lineage;
        const createdData = {
          value: element[i],
          lineage,
        };
        if (!lineage) {
          delete createdData.lineage;
        }
        if (currentProxyElement.id) {
          lineageData[currentProxyElement.values[propertiesId[i]][0].id] = createdData;
        }
      }
    }
  });
  const _ontology = ontology;
  for (let i = 0; i < _ontology.fields.length; i++) {
    const field = _ontology.fields[i];
    for (let j = 0; j < field.children.length; j++) {
      const children = field.children[j];
      for (let propI = 1; propI < propertiesId.length; propI++) {
        const elem = children.values[propertiesId[propI]][0];

        const newData = { ...elem };
        if (lineageData[elem.id].lineage) {
          newData.lineage = lineageData[elem.id].lineage;
        }
        if (children.data_type === "Date") {
          if (newData.value) {
            newData.value = dayjs(newData.value).utc(true).toISOString();
          }
        }
        children.values[propertiesId[propI]][0] = newData;
      }
    }
  }
  return _ontology;
};

export const disableActions = async () => {
  /*  const buttonSave = { id: "Save", enabled: false, visible: true };
  const buttonFinish = { id: "Finish", enabled: false, visible: true };
  const buttonReopen = { id: "Reopen", enabled: false, visible: true };

  const parentGroup = { id: "GroupActions", controls: [buttonSave, buttonFinish, buttonReopen] };
  const parentTab = { id: "TabCustom", groups: [parentGroup] };
  const ribbonUpdater = [parentTab];
  await Office.ribbon.requestUpdate({
    tabs: ribbonUpdater,
  }); */
};

export const enabledSaveFinish = async (/* enabled */) => {
  /* const buttonSave = { id: "Save", enabled: enabled, visible: true };
  const buttonFinish = { id: "Finish", enabled: enabled, visible: true };
  const buttonReopen = { id: "Reopen", enabled: false, visible: true };

  const parentGroup = { id: "GroupActions", controls: [buttonSave, buttonFinish, buttonReopen] };
  const parentTab = { id: "TabCustom", groups: [parentGroup] };
  const ribbonUpdater = [parentTab];
  await Office.ribbon.requestUpdate({
    tabs: ribbonUpdater,
  }); */
};

export const enabledReopen = async () => {
  /*  const buttonReopen = { id: "Reopen", enabled: true, visible: true };
  const parentGroup = { id: "GroupActions", controls: [buttonReopen] };
  const parentTab = { id: "TabCustom", groups: [parentGroup] };
  const ribbonUpdater = [parentTab];
  await Office.ribbon.requestUpdate({
    tabs: ribbonUpdater,
  }); */
};

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.saveHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        getInsertedData();
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.finishHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        finishSavedDocument();
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.reopenHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        const scopeId = localStorage.getItem("scopeId");

        reopenDocument(scopeId).then(async () => {
          await enabledSaveFinish(true);
        });
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});

export async function getInsertedData() {
  try {
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      if (!sheet) {
        console.error("Error: No active worksheet found.");
        return null;
      }

      const usedRange = sheet.getUsedRange();
      usedRange.load("values"); // Load the values property of the range

      await context.sync(); // Sync the context to get the values

      const data = usedRange.values;
      console.log("Data retrieved successfully:", data);

      const request = indexedDB.open("ontologyDatabase", 1);

      request.onsuccess = function (event) {
        const db = event.target.result;

        // 2. Start a transaction to read from the object store
        const transaction = db.transaction("ontologyObjectStore", "readonly");
        const objectStore = transaction.objectStore("ontologyObjectStore");

        // 3. Read a specific object by key
        const scopeId = localStorage.getItem("scopeId");
        const getRequest = objectStore.get(scopeId);

        getRequest.onsuccess = async function () {
          if (getRequest.result) {
            console.log("Object retrieved:", getRequest.result);
            const ontology = getRequest.result.application;
            if (ontology.fields) {
              const propertiesId = ontology.properties.map(({ id }) => id);
              //need optimization
              // return
              // Event listener for selection change
              try {
                await Excel.run({ delayForCellEdit: true }, async (context) => {
                  const activeSheet = context.workbook.worksheets.getActiveWorksheet();

                  activeSheet.load("id");
                  await context.sync(); // Sync the workbook state

                  console.log(`Current active sheet: ${activeSheet.id}`);

                  await context.sync();
                  const mergedData = await mergeOntologyData(
                    ontology,
                    propertiesId,
                    data,
                    cleanWorksheetId(activeSheet.id)
                  );
                  await enabledSaveFinish(false);
                  saveDocument(scopeId, mergedData).then(async () => {
                    await enabledSaveFinish(true);
                    const transaction = db.transaction("ontologyObjectStore", "readwrite");
                    const objectStore = transaction.objectStore("ontologyObjectStore");
                    const updateRequest = objectStore.put({ id: scopeId, application: mergedData });
                    updateRequest.onsuccess = function () {
                      console.log("Object updated successfully:", mergedData);
                    };
                    updateRequest.onerror = function () {
                      console.log("Error updating object");
                    };
                  });
                });
              } catch (error) {
                console.error("Error getting active sheet name: ", error);
              }
              setValues(ontology, propertiesId, data);
            }
          } else {
            console.log("No record found for the given key");
          }
        };

        getRequest.onerror = function () {
          console.log("Error retrieving object");
        };
      };
    });
  } catch (error) {
    console.error("Error retrieving data:", error);
    return null;
  }
}

function setValues(ontology, propertiesId, data) {
  for (let i = 0; i < ontology.fields.length; i++) {
    const field = ontology.fields[i];
    for (let j = 0; j < field.children.length; j++) {
      const children = field.children[j];
      const childrenLabel = children.label;
      propertiesId.map((id, index) => {
        const value = children.values[id][0];
        if (!value.locked) {
          const itemValue = data.find((el) => Array.isArray(el) && el[0] === childrenLabel);
          if (itemValue && itemValue.length >= index) {
            value.value = itemValue[index];
          }
        }
      });
    }
  }
}

export async function finishSavedDocument() {
  try {
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      if (!sheet) {
        console.error("Error: No active worksheet found.");
        return null;
      }

      const request = indexedDB.open("ontologyDatabase", 1);

      request.onsuccess = function (event) {
        const db = event.target.result;

        // 2. Start a transaction to read from the object store
        const transaction = db.transaction("ontologyObjectStore", "readonly");
        const objectStore = transaction.objectStore("ontologyObjectStore");

        // 3. Read a specific object by key
        const scopeId = localStorage.getItem("scopeId");
        const getRequest = objectStore.get(scopeId);

        getRequest.onsuccess = async function () {
          if (getRequest.result) {
            console.log("Object retrieved:", getRequest.result);
            const ontology = getRequest.result.application;
            if (ontology.fields) {
              await enabledSaveFinish(false);

              finishDocument(scopeId, ontology).then(async () => {
                await enabledReopen();
              });
            }
          } else {
            console.log("No record found for the given key");
          }
        };

        getRequest.onerror = function () {
          console.log("Error retrieving object");
        };
      };

      // Get the range of the used data

      //   return data; // Return the data for further processing
    });
  } catch (error) {
    console.error("Error retrieving data:", error);
    return null;
  }
}
