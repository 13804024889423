import axiosInstance from "./apiService";

const FIS_AGENT_BANK_IRI = "http://www.cognaize.com/ontologies/Notices/AgentBank/";

export const saveDocument = async (scopeId, application) => {
  try {
    const { data } = await axiosInstance.post(`/document-data`, {
      scopeId,
      ontologyIri: FIS_AGENT_BANK_IRI,
      data: application,
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const finishDocument = async (scopeId, application) => {
  try {
    const { data } = await axiosInstance.post(`/document-data/finish`, {
      scopeId,
      ontologyIri: FIS_AGENT_BANK_IRI,
      data: application,
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const reopenDocument = async (scopeId) => {
  try {
    const { data } = await axiosInstance.patch(`/document-data/reopen/${scopeId}`);
    return data.data;
  } catch (error) {
    return error;
  }
};
