// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/png/notAuthorizedBG.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unauthorized_view{height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;align-items:flex-end;justify-content:center;width:100%;padding:15px;display:flex}.unauthorized_container{align-items:center;width:100%;max-width:368px;height:100%;max-height:600px;display:flex;flex-direction:column;justify-content:space-between}.unauthorized_container>img{width:131px}.unauthorized_container p{margin:0}.unauthorized_container .unauthorized_text_block{color:#fff;text-align:center}.unauthorized_container .unauthorized_text_block .title_text{font-size:34px;line-height:36px;margin-bottom:10px}.unauthorized_container .unauthorized_text_block .regular_text{font-size:14px}.unauthorized_container .unauthorized_footer p{font-size:14px;text-align:center;color:hsla(0,0%,100%,.8);margin-bottom:15px}.unauthorized_container .unauthorized_footer .btn{width:100%}", "",{"version":3,"sources":["webpack://./src/taskpane/pages/Unauthorized/unauthorized.style.scss"],"names":[],"mappings":"AAAA,mBACE,YAAA,CACA,wDAAA,CACA,2BAAA,CACA,qBAAA,CACA,oBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA,CACA,YAAA,CAEF,wBAIE,kBAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CACA,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CAVA,4BACE,WAAA,CAUF,0BACE,QAAA,CAEF,iDACE,UAAA,CACA,iBAAA,CAEA,6DACE,cAAA,CACA,gBAAA,CACA,kBAAA,CAEF,+DACE,cAAA,CAIF,+CACE,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CAEF,kDACE,UAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
