/* global Office window  console*/
Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.enableLinage = async (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        const buttonLineage = { id: "LineageEnable", enabled: false, visible: true };
        const buttonDisableLineage = { id: "LineageDisable", enabled: true, visible: true };

        const parentGroup = { id: "GroupLineage", controls: [buttonLineage, buttonDisableLineage] };
        const parentTab = { id: "TabCustom", groups: [parentGroup] };
        const ribbonUpdater = [parentTab];
        await Office.ribbon.requestUpdate({
          tabs: ribbonUpdater,
        });
        window["lineageEnabled"] = "on";
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});
Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.disableLineage = async (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        const buttonLineage = { id: "LineageEnable", enabled: true, visible: true };
        const buttonDisableLineage = { id: "LineageDisable", enabled: false, visible: true };

        const parentGroup = { id: "GroupLineage", controls: [buttonLineage, buttonDisableLineage] };
        const parentTab = { id: "TabCustom", groups: [parentGroup] };
        const ribbonUpdater = [parentTab];
        await Office.ribbon.requestUpdate({
          tabs: ribbonUpdater,
        });
        window["lineageEnabled"] = "off";
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});
