/* global Proxy localStorage Excel*/

/**
 * generates unique id
 *
 * @returns string
 */
export const generateUniqueId = () => {
  return "id-" + Date.now() + "-" + Math.floor(Math.random() * 1000);
};

export const createProxy = (storageKey, target = {}) => {
  const listeners = [];

  // Load the proxy state from localStorage (or start with the provided target if not found)
  const loadProxyState = () => {
    const savedState = localStorage.getItem(storageKey);
    return savedState ? JSON.parse(savedState) : target;
  };

  // Function to save the proxy state to localStorage
  const saveProxyState = (obj) => {
    localStorage.setItem(storageKey, JSON.stringify(obj));
  };

  const handler = {
    get(obj, prop) {
      if (prop === "addListener") {
        return function (listener) {
          listeners.push(listener);
          return () => {
            // Return a function to remove this specific listener
            const index = listeners.indexOf(listener);
            if (index > -1) {
              listeners.splice(index, 1);
            }
          };
        };
      }
      if (prop === "clearListeners") {
        return function () {
          listeners.length = 0; // Clear all listeners
        };
      }
      return Reflect.get(obj, prop);
    },
    set(obj, prop, value) {
      const oldValue = obj[prop];
      const result = Reflect.set(obj, prop, value);
      if (result && oldValue !== value) {
        listeners.forEach((listener) => listener(prop, oldValue, value));
        saveProxyState(obj); // Save state to localStorage after each change
      }
      return result;
    },
    deleteProperty(obj, prop) {
      const oldValue = obj[prop];
      const result = Reflect.deleteProperty(obj, prop);
      if (result) {
        listeners.forEach((listener) => listener(prop, oldValue, undefined));
        saveProxyState(obj); // Save state to localStorage after deletion
      }
      return result;
    },
  };

  // Initialize the proxy with the loaded state
  return new Proxy(loadProxyState(), handler);
};

export const cleanWorksheetId = (id) => {
  return id.replace("{", "").replace("}", "");
};

export const handleSetScopeIdToCell = (scopeId) => {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    context.workbook.worksheets.getActiveWorksheet();
    const cell = context.workbook.getSelectedRange();
    cell.format.autofitColumns();
    cell.values = [[scopeId]];
    context.sync();
  });
};
