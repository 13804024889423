/* eslint-disable react/prop-types */
/*global */
import React from "react";
import ListItem from "./ListItem";
const List = (props) => {
  const { files, selectFile } = props;
  const columns = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Upload date",
      key: "creationDate",
    },
  ];

  const renderData = () => {
    const data = files.map((item) => {
      return (
        <div className={"table_row"} key={item.id} onClick={() => selectFile(item.id)}>
          <ListItem columns={columns} file={item} />
        </div>
      );
    });

    return data;
  };
  return <div className="table_scroll_container">{renderData()}</div>;
};

export default List;
