import axiosInstance from "./apiService";

/*global*/

export const getFiles = async ({ pageNumber = 0, pageSize = 10, search, status }) => {
  const { data } = await axiosInstance.post(`/file-details/filterDocument`, {
    pageNumber: pageNumber,
    pageSize,
    name: search,
    status,
  });
  return data;
};
export const getFile = async (scopeId) => {
  const { data } = await axiosInstance.get(`/file-details/findById/${scopeId}`);
  return data.data;
};

export const getApplication = async (scopeId) => {
  try {
    const { data } = await axiosInstance.get(`/document-data/${scopeId}`, {
      params: {
        ontologyIri: encodeURI("http://www.cognaize.com/ontologies/Notices/AgentBank/"),
      },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};
