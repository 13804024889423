import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import PdfViewer from "./PdfViewer";

import { getApplication, getFile } from "../../../api/endoints";
import { insertOntologies } from "../../taskpane";
import { enabledReopen, enabledSaveFinish } from "../../../commands/actionsCommands";
import { BranchFork20Filled, DocumentCopy20Filled, DrawShape20Regular } from "@fluentui/react-icons";
import { useParams } from "react-router-dom";

/*global console  indexedDB localStorage*/
import "./documentViewer.style.scss";
import { handleSetScopeIdToCell } from "../../utils/utils";
import AppTooltip from "../../../reusableComponents/AppTooltip";
const DocumentViewer = () => {
  const { scopeId: scopeIdFromRoute } = useParams();
  const [fileData, setFileData] = useState(null);
  const [isLoading, setFilesLoading] = useState();
  const [drawingEnabled, setDrawingEnable] = useState(false);

  useEffect(() => {
    scopeIdFromRoute &&
      getFile(scopeIdFromRoute)
        .then(async (response) => {
          const file = response;
          const status = response.status;
          if (status === "Approved" || status === "Approval in Progress") {
            await enabledSaveFinish(false);
            await enabledReopen();
          } else {
            await enabledSaveFinish(true);
          }
          setFileData(file);

          setFilesLoading(false);
        })
        .catch((error) => {
          console.log(error, "response --- error");

          setFilesLoading(false);
        });
    setFilesLoading(true);
  }, [scopeIdFromRoute]);

  const handleApplyDocClick = () => {
    getApplication(scopeIdFromRoute).then((response) => {
      const application = response;
      console.log(application, "application");
      const request = indexedDB.open("ontologyDatabase", 1);

      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        // 2. Create an object store if it doesn't exist
        if (!db.objectStoreNames.contains("ontologyObjectStore")) {
          db.createObjectStore("ontologyObjectStore", { keyPath: "id" });
        }
      };
      request.onsuccess = function (event) {
        const db = event.target.result;

        // 3. Start a transaction to perform read/write operations
        const transaction = db.transaction("ontologyObjectStore", "readwrite");
        const objectStore = transaction.objectStore("ontologyObjectStore");
        objectStore.clear();

        // 5. Store the object
        localStorage.setItem("scopeId", scopeIdFromRoute);
        const storeRequest = objectStore.add({ id: scopeIdFromRoute, application });

        storeRequest.onsuccess = function () {
          console.log("Object added successfully");
        };

        storeRequest.onerror = function () {
          console.log("Error adding object");
        };
      };

      const header = application.properties?.map(({ label }) => label);
      const headerValueId = application.properties[1].id;
      if (headerValueId) {
        const ontology = application.fields?.map(({ label, children }) => [
          ...children.map((item) => {
            const { label, values } = item;
            return { ...item, groupValue: [label, values[headerValueId][0].value] };
          }),
          { groupValue: [label, ""] },
        ]);
        insertOntologies({ ontology, header, sheetType: "new", headerValueId, scopeId: scopeIdFromRoute });
      }
    });
  };
  const renderFile = useCallback(() => {
    const { preSign } = fileData || {};
    if (!preSign) return null;
    return <PdfViewer src={preSign} scopeId={scopeIdFromRoute} disabled={!drawingEnabled} />;
  }, [fileData, drawingEnabled]);

  return (
    <div className="document-viewer-page">
      <div className="document-viewer-header">
        <div className="file-name p4">{fileData?.fileDetails?.name}</div>
        <div className="document-actions">
          <div className={`doc-action-button set-enable-drawing`} onClick={() => setDrawingEnable(!drawingEnabled)}>
            <AppTooltip tipMessage="Draw Box for Coordinates">
              <DrawShape20Regular />
            </AppTooltip>
            {!drawingEnabled && <span className="drawing-disabled-line" />}
          </div>
          <div
            className="doc-action-button set-doc-scope-id"
            onClick={() => {
              handleSetScopeIdToCell(fileData?.fileDetails?.id);
            }}
          >
            <AppTooltip tipMessage="Copy ID">
              <DocumentCopy20Filled />
            </AppTooltip>
          </div>
          <div className="doc-action-button apply-ontology-button" onClick={handleApplyDocClick}>
            <AppTooltip tipMessage="Set Ontology Fields">
              <BranchFork20Filled />
            </AppTooltip>
          </div>
        </div>
      </div>

      <div className="pdf-view-scroll-wrapper">
        {isLoading ? <div>Loading...</div> : fileData && <div style={{ position: "relative" }}>{renderFile()}</div>}
      </div>
    </div>
  );
};

DocumentViewer.propTypes = {
  id: PropTypes.string,
  sheetType: PropTypes.string,
};

export default DocumentViewer;
