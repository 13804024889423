import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dismiss16Filled } from "@fluentui/react-icons";

import "./documentsSearch.style.scss";
/* global setTimeout clearTimeout*/

const DocumentsSearch = ({ callback, disabled }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      callback(searchTerm);
    }
  };

  const cleanSearch = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callback(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  return (
    <div className="documents_search_container">
      <input
        disabled={disabled}
        type="text"
        className="document_search_input"
        placeholder="Enter your command here"
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {searchTerm !== "" && <Dismiss16Filled onClick={() => cleanSearch()} className="icon_style_clean" />}
    </div>
  );
};

export default DocumentsSearch;

DocumentsSearch.propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
};
