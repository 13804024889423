import React from "react";
import { Redirect } from "react-router-dom";
import useBroadCastUserChannel from "../hooks/useBroadCastUserChannel";
/* global */
// eslint-disable-next-line react/prop-types
const UnauthorizedRoutes = ({ children }) => {
  const { loggedIn } = useBroadCastUserChannel();
  if (loggedIn) return <Redirect to="/documents" />;
  return children;
};

export default UnauthorizedRoutes;
