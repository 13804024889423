import React from "react";
import { Redirect } from "react-router-dom";
import useBroadCastUserChannel from "../hooks/useBroadCastUserChannel";
/* global */
// eslint-disable-next-line react/prop-types
const AuthWrapper = ({ children }) => {
  const { loggedIn } = useBroadCastUserChannel();
  if (!loggedIn) return <Redirect to="/unauthorized" />;
  return children;
};

export default AuthWrapper;
