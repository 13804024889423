/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChevronLeft16Filled, ChevronRight16Filled } from "@fluentui/react-icons";

import Select from "react-select";
import { components } from "react-select";

import "./customPagination.style.scss";
/*global setTimeout clearTimeout*/
const options = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 50, label: "50" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "4px",
    border: "1px solid #E8E8E8",
    background: "#ffffff",
    color: "#191919",
    boxShadow: "none",
    height: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderColor: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#191919",
    height: "30px",
    minHeight: "30px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "26px",
    cursor: "pointer",
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "black",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    marginBottom: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0px",
    height: "16px",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span style={{ fontSize: "10px", color: "#E8E8E8", lineHeight: "38px", height: "25px" }}>▼</span>
    </components.DropdownIndicator>
  );
};

const CustomInput = (props) => (
  <components.Input {...props} readOnly /> // Make the input read-only
);

const CustomPagination = ({ pageCount, onPageChange, pageSize = 10, onPageSizeChange, pageNumber }) => {
  const pageInput = useRef(null);
  const currentPageRef = useRef(1);
  const [page, setPage] = useState(pageNumber);

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
    setPage(newPage);
  };

  const pageManualChange = (e) => {
    const page = Number(e?.target?.value);
    if (page > pageCount || page < 0 || page % 1 !== 0 || e?.target?.value === "0") return;
    setPage(+e?.target?.value);
    if (page) {
      //   onPageChange(page);
      currentPageRef.current = +page;
    }
  };

  const handleBlur = () => {
    const value = Number(page);
    if (value === 0) {
      setPage(currentPageRef.current);
    }
  };

  useEffect(() => {
    if (!page) {
      return;
    }

    const timeoutId = setTimeout(() => {
      onPageChange(page);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [page]);

  const selectedValue = useMemo(() => {
    const valuesArray = options.map((item) => item.value);

    if (valuesArray.includes(pageSize)) {
      return options.find((el) => el.value === pageSize);
    } else {
      return options.find((el) => el.value === 1);
    }
  }, [pageSize]);

  return (
    <div className="pagination">
      <div className="pagination_page_size">
        <Select
          options={options}
          menuPlacement="top"
          className="pagination_footer_dropdown"
          styles={customStyles}
          defaultValue={selectedValue}
          onChange={({ value }) => {
            if (onPageSizeChange) {
              onPageChange(1);
              onPageSizeChange(value);
              setPage(1);
            }
          }}
          components={{ DropdownIndicator, Input: CustomInput }}
        />
      </div>
      <ChevronLeft16Filled
        className={`icon_style_pagination ${page <= 1 ? "pagination_disabled_icon" : ""}`}
        onClick={() => {
          if (page > 1) {
            const newPage = page - 1;
            handlePageChange(newPage);
          }
        }}
      />
      <input
        ref={pageInput}
        className="pagination_input p4"
        value={page}
        type="text"
        min={1}
        max={pageCount}
        onFocus={() => {
          pageInput.current?.select();
        }}
        onBlur={handleBlur}
        onChange={pageManualChange}
      />
      <div className="pagination_count p4">
        <p>/ {pageCount}</p>
      </div>
      <ChevronRight16Filled
        onClick={() => {
          if (page < pageCount) {
            const newPage = page + 1;
            handlePageChange(newPage);
          }
        }}
        className={`icon_style_pagination ${page === pageCount ? "pagination_disabled_icon" : ""}`}
      />
    </div>
  );
};
export default CustomPagination;
