/* global document, Office, module, require   Office window*/

import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import "../commands/index.js";
import "./taskpane.style.scss";
import { createProxy } from "./utils/utils";
const title = "Contoso Task Pane Add-in";

const rootElement = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;
Office.onReady(() => {
  if (!window.storeData) {
    window.storeData = createProxy("storeData");
  }
  if (!window.userData) {
    window.userData = createProxy("userData");
  }
});

Office.onReady(() => {
  root?.render(
    <FluentProvider theme={webLightTheme}>
      <App title={title} />
    </FluentProvider>
  );
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}
