import React from "react";
import SideBar from "../SideBar";
import "./layout.scss";
// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  return (
    <div className="app_layout">
      <SideBar />
      <div className="layout_content">{children}</div>
    </div>
  );
};
export default Layout;
