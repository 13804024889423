// axiosApiService.js
/*global  localStorage console BroadcastChannel process*/
const userChannel = new BroadcastChannel("user-channel");

import axios from "axios";
const API_URL = process.env.API_URL;

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: API_URL, // Replace with your API base URL
  timeout: 100000000,
});

// Interceptor to handle requests
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    if (!config.skipAuth && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Interceptor to handle responses
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log(error, "error");

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const { data } = await axios.post(`${API_URL}/auth/refresh`, {
            refreshToken,
          });
          localStorage.setItem("accessToken", data.data.accessToken);
          localStorage.setItem("refreshToken", data.data.refreshToken);
          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.data.accessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error("Refresh token error", refreshError);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          userChannel.postMessage({ type: "LOGOUT" });

          // Redirect to login or handle token refresh error
        }
      } else {
        // Redirect to login or handle missing refresh token
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        userChannel.postMessage({ type: "LOGOUT" });
      }
    } else if (error?.response?.status === 403) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      userChannel.postMessage({ type: "LOGOUT" });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
