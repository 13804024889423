import React from "react";
import LoadingCircle from "../LoadingCircle";
import PropTypes from "prop-types";

import "./layerLoading.style.scss";
const LayerLoading = ({ size = "md" }) => {
  return (
    <div className="layer_loading">
      <LoadingCircle size={size} />
    </div>
  );
};

export default LayerLoading;
LayerLoading.propTypes = {
  size: PropTypes.string,
};
