/* global Office console Office window */

Office.onReady(function (info) {
  if (info.host === Office.HostType.Excel) {
    console.log("Excel add-in ready");

    window.showTaskPane = async (event) => {
      Office.addin
        .showAsTaskpane()
        .then(async () => {
          console.log("Task pane displayed successfully!");
          //   window.userHandler(event);
          try {
            // const buttonSave = { id: "Save", enabled: true, visible: true };
            // const buttonFinish = { id: "Finish", enabled: true, visible: true };
            // const parentGroup = { id: "GroupActions", controls: [buttonSave, buttonFinish] };
            // const parentTab = { id: "TabCustom", groups: [parentGroup] };
            // const ribbonUpdater = [parentTab];
            // await Office.ribbon.requestUpdate({
            //   tabs: ribbonUpdater,
            // });
          } catch (error) {
            console.log(error, "error ");
          }
          event?.completed();
        })
        .catch((error) => {
          console.error("Error showing task pane: ", error);
          event?.completed();
        });
    };
  }
});
